(function() {
    (function($) {
      var renderError;

      $.errors = {
        feedbackAttribute: "id",
        feedbackActvieClass: "is-invalid",
        feedbackFormat: "<div class='validation-container invalid-feedback'><div class='validation-feedback'></div></div>",
        feedbackContainerClass: "validation-container",
        feedbackClass: "validation-feedback"
      };

      renderError = function(form, validationField, valiadationMessage) {
        var element, feedbackElement;

        validationField = validationField.split(/[_]/g).join("-");

        if (validationField.indexOf(".") !== -1) { //If array notation found
          var validationArrField = validationField;
          validationArrField = validationArrField.split(/[.]/g).join("-");

          if (form.find("["+ $.errors.feedbackAttribute +"="+ validationArrField +"]").parent().parent().length !== 0) { //if we can find using default attr
            validationField = validationArrField;
          } else { //look for using name/id attributes array notation
            var validationArrFieldType1 = validationField;
            var validationArrFieldType2 = validationField;
            validationArrFieldType1 = validationArrFieldType1.replace(/[0-9.]/g, "").trim();
            validationArrFieldType2 = validationArrFieldType2.replace(/[0-9]/g, "").trim();
            var validationArrFieldType3 = validationArrFieldType2.replace(/([.]*)$/, "").split(/[.]/g).join("-").trim();
            var validationArrFieldType2Parent = validationArrFieldType2.replace(/([.]*)$/, "").split(/[.]/g)[0];
            validationArrFieldType2 = validationArrFieldType2.replace(/([.]*)$/, "").split(/[.]/g);
            validationArrFieldType2.shift();
            validationArrFieldType2 = validationArrFieldType2.map(i => '[' + i + ']').join('').trim();

            if (form.find("[name='"+ validationArrFieldType1 +"[]']").parent().parent().length !== 0) {
              validationField = validationArrFieldType1;
              element = form.find("[name='"+ validationField +"[]']").parent().parent();
            } else if (form.find("[id='"+ validationArrFieldType1 +"-error-feedback']").length !== 0) {
              validationField = validationArrFieldType1;
              element = form.find("[id='"+ validationArrFieldType1 +"-error-feedback']");
            } else if (form.find("[name='"+ validationArrFieldType2Parent +""+ validationArrFieldType2 +"[]']").parent().parent().length !== 0) {
              validationField = validationArrFieldType2;
              element = form.find("[name='"+ validationArrFieldType2Parent +""+ validationArrFieldType2 +"[]']").parent().parent();
            } else if (form.find("[id='"+ validationArrFieldType2Parent +"-"+ validationArrFieldType2 +"-error-feedback']").length !== 0) {
              validationField = validationArrFieldType2;
              element = form.find("[id='"+ validationArrFieldType2Parent +"-"+ validationArrFieldType2 +"-error-feedback']");
            } else if (form.find("[id='"+ validationArrFieldType3 +"']").parent().parent().length !== 0) {
              validationField = validationArrFieldType3;
              element = form.find("[id='"+ validationArrFieldType3 +"']").parent().parent();
            } else if (form.find("[id='"+ validationArrFieldType3 +"-error-feedback']").length !== 0) {
              validationField = validationArrFieldType3;
              element = form.find("[id='"+ validationArrFieldType3 +"-error-feedback']");
            } else { //no element found just skip
              validationField = null;
            }
          }
        } else if (form.find("[name="+ validationField +"]").parent().parent().length !== 0) { //simple name look
          element = form.find("[name="+ validationField +"]").parent().parent();
        } else if (form.find("[id='"+ validationField +"-error-feedback']").length !== 0) {
          element = form.find("[id='"+ validationField +"-error-feedback']");
        }

        if (validationField !== null) {
          if (!element) { //if element not defined look for it using default conf
            element = form.find("["+ $.errors.feedbackAttribute +"="+ validationField +"]").parent().parent();
          }

          if (!element.hasClass($.errors.feedbackActvieClass)) {
              element.addClass($.errors.feedbackActvieClass);
              element.closest( ".form-group" ).addClass($.errors.feedbackActvieClass);
              feedbackElement = element.find("." + $.errors.feedbackClass);

              if (feedbackElement.length === 0) {
                if (form.hasClass('form-general')) {
                  element.append('<div class="col-12">' + $.errors.feedbackFormat + '</div>');
                } else {
                  element.append($.errors.feedbackFormat);
                }
              }
              feedbackElement = element.find("." + $.errors.feedbackClass);
              if (feedbackElement.length > 0) {
                  return feedbackElement.html(valiadationMessage);
              }

              if (window.exeFormOptions.notificationType === 'modal' || typeof window.exeFormOptions.notificationType === 'undefined') {
                Swal.fire({
                  icon: 'error',
                  title: valiadationMessage,
                  confirmButtonText: 'Ok',
                });
              } else if (window.exeFormOptions.notificationType === 'toast') {
                Toast.fire({
                  icon: 'error',
                  title: valiadationMessage,
                });
              } else {
                console.warning('Exeform notificationType is invalid!');
                console.error(valiadationMessage);
              }
          } else { //if error already exists
            feedbackElement = element.find("." + $.errors.feedbackContainerClass);
            if (feedbackElement.length > 0) { //just append the error as child
                return feedbackElement.append('<div class="'+ $.errors.feedbackClass +'">' + valiadationMessage +'</div>');
            }
          }
        }
      };

      $.fn.renderErrors = function(errors) {
        $(this).resetErrors();

        return $(this).buildErrors(errors);
      };

      $.fn.buildErrors = function(errors) {
        var form, _errors;
        form = $(this);

        if (typeof errors === "object") {
          _errors = errors;
          errors = [];

          $.each(_errors, function(key, value) {
            if (value && !($.isArray(value) && value.length === 0)) {
              return errors.push([key, value]);
            }
          });
        }

        return $(errors).each(function(key, error) {
          var validationField, valiadationMessage;
          validationField = error[0];
          valiadationMessage = error[1];

          if ($.isArray(valiadationMessage)) {
            valiadationMessage = valiadationMessage.join(', </br>');
          }

          return renderError(form, validationField, valiadationMessage);
        });
      };

      $.fn.unserialize = function (serializedString) {
        let str = decodeURIComponent(serializedString);
        let pairs = str.split('&');
        let obj = {}, p, idx, val;

        for (let i = 0, n = pairs.length; i < n; i++) {
          p = pairs[i].split('=');
          idx = p[0];
    
          if (idx.indexOf("[]") == (idx.length - 2)) {
            // Eh um vetor
            let ind = idx.substring(0, idx.length - 2);

            if (obj[ind] === undefined) {
              obj[ind] = [];
            }

            obj[ind].push(p[1]);
          }
          else {
            obj[idx] = p[1];
          }
        }

        return obj;
      };

      return $.fn.resetErrors = function() {
        if ($(this).hasClass('js-validate')) {
          $(this).find('.' + $.errors.feedbackActvieClass).removeClass($.errors.feedbackActvieClass);
          $(this).data('validator').resetForm();
        }
        if ($(this).hasClass('form-general')) {
          $(this).find("[class="+ $.errors.feedbackClass +"]").parent().parent().parent().removeClass($.errors.feedbackActvieClass);
          $(this).find("[class="+ $.errors.feedbackClass +"]").parent().parent().remove();
        } else {
          $(this).find("[class="+ $.errors.feedbackClass +"]").parent().parent().removeClass($.errors.feedbackActvieClass);
          $(this).find("[class="+ $.errors.feedbackClass +"]").parent().remove();
        }
      };
    })(jQuery);

  }).call(this);

  (function() {
    (function($) {
      var exeFormErrorHandler, exeFormSuccessHandler, exeOTPHandler;

      $.fn.exeSubmit = function(options, overrides) {
        var $form, url, method, data, beforeSendCallback, successCallback, errorCallback, $formOtp, hasOTP = false, token = null, preventAuthReload = false, errorMessageNotify = true;

        if (options === null) {
          options = {};
        }
        if (overrides === null || typeof overrides === 'undefined') {
          overrides = {};
        }

        $form = $(this);

        options.formId = $form.attr('id');

        window.exeFormOptions = options;

        $form.resetErrors();//Reset any errors if exists

        if (options.jsValidations) {
          $form.validate({
              ignore: ":hidden", errorClass: "invalid-feedback", errorElement: "div", errorPlacement: function (e, r) {
                  jQuery(r).parents(".form-group > div").append(e)
              }, highlight: function (e) {
                  jQuery(e).closest(".form-group").removeClass("is-invalid").addClass("is-invalid")
              }, success: function (e) {
                  jQuery(e).closest(".form-group").removeClass("is-invalid"), jQuery(e).remove()
              },
              rules: options.jsValidations.rules,
              messages: options.jsValidations.messages
          });

          $form.addClass('js-validate');

          if (!$form.valid()) {
            Toast.fire({
              icon: 'error',
              title: 'Oops! Please check all fields are filled in correctly.',
            });

            return false;
          }
        }

        if(overrides.hasOTP && overrides.token) {
          hasOTP = true;
          token = overrides.token;
        }
        
        var otpText = "your contact mobile number";

        if (options.otp && options.otpEndpoint) {
          options.otpEndpoint.errorMessageNotify = (typeof options.otpEndpoint.errorMessageNotify != 'undefined') ? options.otpEndpoint.errorMessageNotify : false;
          
          let otpData = options.otpEndpoint.data;

          if (otpData.action == "OTPOverEmail") {
              otpText = "the given email address";
          }

          if (otpData.action == "OTPOverSMS") {
            otpText = "the given mobile number";
          }
        }

        if (options.otp && options.otpEndpoint && ! hasOTP && $form.valid()) {
                 
          var html = '<div id="'+ $form.attr('id') +'-otp-form-wrapper" class="bg-white-op-95 position-absolute otp-form-wrapper">';
          html += '<div class="row mx-0 justify-content-center">';
          html += '<div class="col-lg-6">';
          html += '<div class="p-0 content content-full overflow-hidden">';
          html += '<div class="block block-themed block-rounded block-shadow">';
          html += '<div class="block-header bg-indigo-pulse">';
          html += '<h3 class="block-title">Unlock</h3>';
          html += '</div>';
          html += '<div class="block-content">';
          html += '<div class="form-group text-center">';
          html += '<p>This form is protected with 2 step verification by default. We will send you a verification code to ' + otpText + ' to unlock this form.</p>';
          html += '</div>';
          html += '<form id="'+ $form.attr('id') +'-otp-form"class="otp-form form-general" method="post" novalidate>';
          html += '<div class="form-group row otp-form-lock-token-wrapper d-none">';
          html += '<div class="col-12">';
          html += '<label for="'+ $form.attr('id') +'-otp-form-lock-token">Token</label>';
          html += '<input type="text" class="form-control" id="'+ $form.attr('id') +'-otp-form-lock-token" name="token" required>';
          html += '</div>';
          html += '</div>';
          html += '<div class="form-group text-center '+ $form.attr('id') +'-otp-form-lock-unlock-button-wrapper">';
          html += '<button type="button" class="btn btn-alt-primary" id="'+ $form.attr('id') +'-otp-form-lock-unlock-button">';
          html += '<i class="si si-lock-open mr-10"></i> Unlock';
          html += '</button>';
          html += '</div>';
          html += '<div class="form-group text-center '+ $form.attr('id') +'-otp-form-lock-verify-button-wrapper d-none">';
          html += '<button type="submit" class="btn btn-alt-primary" id="'+ $form.attr('id') +'-otp-form-lock-verify-button">';
          html += '<i class="si si-lock-open mr-10"></i> Verify';
          html += '</button>';
          html += '</div>';
          html += '<div class="form-group text-center '+ $form.attr('id') +'-otp-form-lock-token-resend-wrapper d-none">';
          html += '<a id="'+ $form.attr('id') +'-otp-form-lock-token-resend" class="link-effect text-muted mr-10 mb-5 d-none" href="javascript:void(0)">';
          html += '<i class="fa fa-user text-muted mr-5"></i> Resend Token';
          html += '</a>';
          html += '</div>';
          html += '</form>';
          html += '</div>';
          html += '</div>';
          html += '</div>';
          html += '</div>';
          html += '</div>';
          html += '</div>';

          $form.parent().find('#'+ $form.attr('id') +'-otp-form-wrapper').remove();
          $form.parent().prepend(html);

          var $formOtpWrapper = $('#'+ $form.attr('id') +'-otp-form-wrapper');
          $formOtp = $('#'+ $form.attr('id') +'-otp-form');

          $formOtp.bind("submit", function(event) {
            event.preventDefault();

            $formOtp.validate({
                ignore: [":hidden"], errorClass: "invalid-feedback", errorElement: "div", errorPlacement: function (e, r) {
                    jQuery(r).parents(".form-group > div").append(e)
                }, highlight: function (e) {
                    jQuery(e).closest(".form-group").removeClass("is-invalid").addClass("is-invalid")
                }, success: function (e) {
                    jQuery(e).closest(".form-group").removeClass("is-invalid"), jQuery(e).remove()
                }
            });

            $formOtp.addClass('js-validate');

            if (!$formOtp.valid()) {
              return false;
            }

            if ($formOtp.hasClass('otp-sent')) {
              token = $formOtp.find('#'+ $form.attr('id') +'-otp-form-lock-token').val();
              hasOTP = true;

              $formOtpWrapper.addClass('d-none');

              $form.exeSubmit(options, {
                hasOTP: true,
                token: token
              });
            }
          });

          $formOtp.find('#'+ $form.attr('id') +'-otp-form-lock-unlock-button').on("click", function () {
            $formOtp.resetErrors();//Reset any errors if exists
            let data = options.otpEndpoint.data;

            if (data.action == "OTPOverEmail") {
                let objEmail = $(this).unserialize($form.serialize());
                data.email = objEmail.email;
            }

            if (data.action == "OTPOverSMS") {
              let objMobile = $(this).unserialize($form.serialize());
              data.mobile = objMobile.mobile;
            }

            setTimeout(function () {  
              $('#'+ $form.attr('id') +'-otp-form-lock-token-resend').removeClass('d-none');
              $('#'+ $form.attr('id') +'-otp-form-lock-token-resend').addClass('d-inline-block');
            }, 30 * 1000);

            options.otpEndpoint.data = data;
            exeOTPHandler($formOtp, options);
          });

          $formOtp.find('#'+ $form.attr('id') +'-otp-form-lock-token-resend').on("click", function () {
            $formOtp.resetErrors();//Reset any errors if exists
            exeOTPHandler($formOtp, options);
          });

          return false;
        }

        if (options.redirect && !options.success) {
          options.success = function() {
            setTimeout(function() {
              return window.location = options.redirect;
            }, (typeof window.exeFormOptions.redirectTimeout === 'undefined' ? 600 : window.exeFormOptions.redirectTimeout));
          };
        }
        beforeSendCallback = options.beforeSend;
        successCallback = options.success;
        errorCallback = options.error;
        successNotify = options.successNotify;
        errorMessageNotify = options.errorMessageNotify || true;
        preventAuthReload = options.preventAuthReload || false;
        defaultMessageNotification = (! options.defaultMessageNotification) ?  false : true;

        method = $form.attr("method") || "get";
        url = $form.attr("action");
        data = $form.serialize();

        if (!jQuery.isEmptyObject(options.data)) {
          data = data + "&" + $.param(options.data);
        }

        if (hasOTP && token) {
          data = data + "&token=" + token;
        }

        return $.ajax({
          type: options.type || method,
          url: options.url || url,
          data: data,
          dataType: "json",
          statusCode: {
            401: function() {
              if (preventAuthReload === false) {
                window.location.reload();
              }
            },
            403: function() {
              if (preventAuthReload === false) {
                window.location.reload();
              }
            },
            419: function() {
              $('#modal-token-expire').modal({
                backdrop: 'static',
                keyboard: false
              });
            }
          },
          beforeSend: function () {
            var html = '<div class="ajax-loader d-flex justify-content-center">';
            html += '<div class="align-self-center">';
            html += '<img src="/media/loader-anim-inline.svg" alt="loading..." class="not-ie" style="width: 100px;">';
            html += '<img src="/media/loader-anim-inline-ie.gif" alt="loading..." class="ie" style="width: 100px;">';
            html += '</div>';
            html += '</div>';

            $form.prepend(html);

            $form.removeClass('position-relative').addClass('position-relative');
            $form.children('.ajax-loader').css('height', ''+ $form.innerHeight() +'px');
            $form.children('.ajax-loader').css('width', ''+ $form.innerWidth() +'px');
            setTimeout(function () {
              $form.children('.ajax-loader').css('height', ''+ $form.innerHeight() +'px');
              $form.children('.ajax-loader').css('width', ''+ $form.innerWidth() +'px');
            },0);

            $form.find(':input[type="submit"]').prop('disabled', true);

            if (defaultMessageNotification) {
              Toast.fire({
                icon: 'info',
                title: 'Please wait while we are processing your request.',
                timer: 60000, // lets give 1mins
                timerProgressBar: true,
              });  
            }
            
            if (typeof beforeSendCallback === "function") {
              return beforeSendCallback.call($form[0]);
            }
          },
          success: function(data) {
            if (!data.redirect) {
              $form.children('.ajax-loader').remove();
              $form.removeClass('position-relative');
            }

            $form.find(':input[type="submit"]').prop('disabled', false);

            return exeFormSuccessHandler($form, data, successCallback, errorCallback, successNotify, errorMessageNotify);
          },
          error: function(xhr, status, str) {
            $form.children('.ajax-loader').remove();
            $form.removeClass('position-relative');

            $form.find(':input[type="submit"]').prop('disabled', false);

            if (typeof xhr.responseJSON != 'undefined' && xhr.responseJSON.errors) {
              if (hasOTP && xhr.responseJSON.errors.hasOwnProperty('token')) {
                hasOTP = false;
                token = null;
                $('#'+ $form.attr('id') +'-otp-form-wrapper').removeClass('d-none');
                $('#'+ $form.attr('id') +'-otp-form').renderErrors(xhr.responseJSON.errors);
              }
            }

            if (xhr.responseJSON.reason == 'locked') {
              errorMessageNotify = false;
            }

            return exeFormErrorHandler($form, xhr, errorCallback, errorMessageNotify);
          }
        });
      };

      exeFormSuccessHandler = function($form, data, successCallback, errorCallback, successNotify, errorMessageNotify) {
        if ($.isEmptyObject(data && data.errors)) {
          if (data.message) {
            if (window.exeFormOptions.notificationType === 'modal' || typeof window.exeFormOptions.notificationType === 'undefined') {
              Swal.fire({
                icon: 'success',
                title: data.message,
                confirmButtonText: 'Ok',
              });
            } else if (window.exeFormOptions.notificationType === 'toast') {
              Toast.fire({
                icon: 'success',
                title: data.message,
              });
            } else {
              console.warning('Exeform notificationType is invalid!');
              console.error(data.message);
            }
          } else if (window.exeFormOptions.successMessage) {
            if (window.exeFormOptions.notificationType === 'modal' || typeof window.exeFormOptions.notificationType === 'undefined') {
              Swal.fire({
                icon: 'success',
                title: window.exeFormOptions.successMessage,
                confirmButtonText: 'Ok',
              });
            } else if (window.exeFormOptions.notificationType === 'toast') {
              Toast.fire({
                icon: 'success',
                title: window.exeFormOptions.successMessage,
              });
            } else {
              console.warning('Exeform notificationType is invalid!');
              console.error(window.exeFormOptions.successMessage);
            }
          }

          if (typeof successCallback === "function") {
            return successCallback.call($form[0], data);
          } else if (data.redirect) {
            if (Swal.isVisible() && !Swal.isLoading()) {
              var confirm = Swal.getConfirmButton();

              if ($(confirm).is(':visible')) {
                $(confirm).on("click", function() {
                  setTimeout(function() {
                    return window.location = data.redirect;
                  }, (typeof window.exeFormOptions.redirectTimeout === 'undefined' ? 600 : window.exeFormOptions.redirectTimeout));
                });
              } else {
                setTimeout(function() {
                  return window.location = data.redirect;
                }, (typeof window.exeFormOptions.redirectTimeout === 'undefined' ? 600 : window.exeFormOptions.redirectTimeout));
              }
            } else {
              setTimeout(function() {
                return window.location = data.redirect;
              }, (typeof window.exeFormOptions.redirectTimeout === 'undefined' ? 600 : window.exeFormOptions.redirectTimeout));
            }
          }
        } else {
          if (typeof errorCallback === "function") {
            errorCallback.call($form, data);
          }

          if (data.message) {
            if (window.exeFormOptions.notificationType === 'modal' || typeof window.exeFormOptions.notificationType === 'undefined') {
              Swal.fire({
                icon: 'error',
                title: xhr.responseJSON.message,
                confirmButtonText: 'Ok',
              });
            } else if (window.exeFormOptions.notificationType === 'toast') {
              Toast.fire({
                icon: 'error',
                title: xhr.responseJSON.message,
              });
            } else {
              console.warning('Exeform notificationType is invalid!');
              console.error(xhr.responseJSON.message);
            }
          }

          return $form.renderErrors(data.errors);
        }
      };

      exeFormErrorHandler = function($form, xhr, errorCallback, errorMessageNotify) {
        if (typeof xhr.responseJSON != 'undefined' && xhr.responseJSON.errors) {
          if (xhr.responseJSON.message) {
            if (errorMessageNotify) {
              if (window.exeFormOptions.notificationType === 'modal' || typeof window.exeFormOptions.notificationType === 'undefined') {
                Swal.fire({
                  icon: 'error',
                  title: xhr.responseJSON.message,
                  confirmButtonText: 'Ok',
                });
              } else if (window.exeFormOptions.notificationType === 'toast') {
                Toast.fire({
                  icon: 'error',
                  title: xhr.responseJSON.message,
                });
              } else {
                console.warning('Exeform notificationType is invalid!');
                console.error(xhr.responseJSON.message);
              }
            }
          }

          if (typeof errorCallback === "function"){
            errorCallback.call($form[0], xhr);
          }
          return $form.renderErrors(xhr.responseJSON.errors);
        } else if (typeof xhr.responseJSON != 'undefined' && xhr.responseJSON.message) {
          if (window.exeFormOptions.notificationType === 'modal' || typeof window.exeFormOptions.notificationType === 'undefined') {
            Swal.fire({
              icon: 'error',
              title: xhr.responseJSON.message,
              confirmButtonText: 'Ok',
            });
          } else if (window.exeFormOptions.notificationType === 'toast') {
            Toast.fire({
              icon: 'error',
              title: xhr.responseJSON.message,
            });
          } else {
            console.warning('Exeform notificationType is invalid!');
            console.error(xhr.responseJSON.message);
          }
        } else if (typeof xhr.responseJSON != 'undefined' && xhr.responseJSON.data.message) {
          if (window.exeFormOptions.notificationType === 'modal' || typeof window.exeFormOptions.notificationType === 'undefined') {
            Swal.fire({
              icon: 'error',
              title: xhr.responseJSON.message,
              confirmButtonText: 'Ok',
            });
          } else if (window.exeFormOptions.notificationType === 'toast') {
            Toast.fire({
              icon: 'error',
              title: xhr.responseJSON.message,
            });
          } else {
            console.warning('Exeform notificationType is invalid!');
            console.error(xhr.responseJSON.message);
          }
        }

        if (typeof errorCallback === "function"){
            errorCallback.call($form[0], xhr);
        }
      }

      exeOTPHandler = function($form, options) {
        return $.ajax({
          type: options.otpEndpoint.method || "get",
          url: options.otpEndpoint.url,
          data: options.otpEndpoint.data,
          dataType: "json",
          statusCode: {
            401: function() {
              window.location.reload();
            },
            403: function() {
              window.location.reload();
            },
            419: function() {
              $('#modal-token-expire').modal({
                backdrop: 'static',
                keyboard: false
              });
            }
          },
          beforeSend: function () {
            var html = '<div class="ajax-loader d-flex justify-content-center">';
            html += '<div class="align-self-center">';
            html += '<img src="/media/loader-anim-inline.svg" alt="loading..." class="not-ie" style="width: 100px;">';
            html += '<img src="/media/loader-anim-inline-ie.gif" alt="loading..." class="ie" style="width: 100px;">';
            html += '</div>';
            html += '</div>';

            $form.prepend(html);

            $form.removeClass('position-relative').addClass('position-relative');
            $form.children('.ajax-loader').css('height', ''+ $form.innerHeight() +'px');
            $form.children('.ajax-loader').css('width', ''+ $form.innerWidth() +'px');
            setTimeout(function () {
              $form.children('.ajax-loader').css('height', ''+ $form.innerHeight() +'px');
              $form.children('.ajax-loader').css('width', ''+ $form.innerWidth() +'px');
            },0);

            $form.find(':input[type="submit"]').prop('disabled', true);

            if (typeof options.otpEndpoint.beforeSend === "function") {
              return options.otpEndpoint.beforeSend.call($form[0]);
            }
          },
          success: function(data) {
            $form.children('.ajax-loader').remove();
            $form.removeClass('position-relative');

            $form.find(':input[type="submit"]').prop('disabled', false);

            if (data) {
              if (data.message) {
                Toast.fire({
                  icon: 'success',
                  title: data.message
                });
              }

              $form.addClass('otp-sent');
              $form.find('.otp-form-lock-token-wrapper').removeClass('d-none');
              if (data.retries) {
                $form.find('.'+ options.formId +'-otp-form-lock-token-resend-wrapper').removeClass('d-none');
              } else {
                $form.find('.'+ options.formId +'-otp-form-lock-token-resend-wrapper').removeClass('d-none').addClass('d-none');
              }

              $form.find('.'+ options.formId +'-otp-form-lock-unlock-button-wrapper').removeClass('d-none').addClass('d-none');
              $form.find('.'+ options.formId +'-otp-form-lock-verify-button-wrapper').removeClass('d-none');
            }

            if (typeof options.otpEndpoint.success === "function") {
              return options.otpEndpoint.success.call($form[0]);
            }
          },
          error: function(xhr, status, str) {
            $form.children('.ajax-loader').remove();
            $form.removeClass('position-relative');

            $form.find(':input[type="submit"]').prop('disabled', false);

            if (typeof xhr.responseJSON != 'undefined' && xhr.responseJSON.errors) {
              if (xhr.responseJSON.message) {
                if (options.otpEndpoint.errorMessageNotify) {
                  Toast.fire({
                    icon: 'error',
                    title: xhr.responseJSON.message
                  });
                }
              }
            } else if (typeof xhr.responseJSON != 'undefined' && xhr.responseJSON.message) {
              Toast.fire({
                icon: 'error',
                title: xhr.responseJSON.message
              });
            }

            let data = options.otpEndpoint.data;

            if (data.action == "OTPOverEmail" || data.action == "OTPOverSMS") {
              if (xhr.status == 422) {
                $('#'+ options.formId).renderErrors(xhr.responseJSON.errors);
                $('#'+ options.formId +'-otp-form-wrapper').addClass('d-none');
              }
            } else {
                $form.find('.'+ options.formId +'-otp-form-lock-unlock-button-wrapper').removeClass('d-none');
                $form.find('.'+ options.formId +'-otp-form-lock-verify-button-wrapper').removeClass('d-none').addClass('d-none');
            }

            if (typeof options.otpEndpoint.error === "function") {
              return options.otpEndpoint.error.call($form[0]);
            }
          }
        });
      }

      return $.fn.exeForm = function(options) {
        if (options === null || typeof options === 'undefined') {
          options = {};
        }

        var $form = $(this);

        if (options.otp && options.otpEndpoint) {
          var formSubmitText = $form.find(':submit').text();
          $form.find(':submit').html('<i class="si si-lock fa-1x"></i> &nbsp;'+formSubmitText);
        }

        return $(this).off('submit').bind("submit", function(event) {
          event.preventDefault();

          return $(this).exeSubmit(options);
        });
      };
    })(jQuery);

  }).call(this);