(function($) {
    $.fn.exeTableInit = function(options) {
      var $table, $tableId, url, data, columns, length, serverSide = true, paging = true, stateSave = false, dataSrc, destroy, destroyed = false, successCallback, errorCallback, ajaxHandler, createdRow;

      if (options === null) {
        options = {};
      }

      $table = $(this);
      $tableId = $(this).attr('id');

      if (typeof options === "function") {
        options.success = options;
      }

      successCallback = options.success;
      errorCallback = options.error;
      url = options.url;
      data = options.data;
      columns = options.columns;
      length = typeof options.length != 'undefined' ? options.length : 30;
      destroy = typeof options.destroy != 'undefined' ? options.destroy : false;
      serverSide = typeof options.serverSide != 'undefined' ? options.serverSide : true;
      paging = typeof options.paging != 'undefined' ? options.paging : true;
      dataSrc = typeof options.dataSrc != 'undefined' ? options.dataSrc : null;
      stateSave = typeof options.stateSave != 'undefined' ? options.stateSave : false;
      createdRow = typeof options.createdRow != 'undefined' ? options.createdRow : false;

      ajaxHandler = function () {
        $table = $table.on('xhr.dt', function ( e, settings, json, xhr ) {
            if (xhr.status >= 400 && xhr.status < 500) {
                $(this).find('tbody').html('<tr><td align="center" colspan="'+ $(this).find('thead tr').children().length +'">Sorry we couldn\'t find any results.</td></tr>');
                $(this).DataTable().clear();

                if (typeof errorCallback === "function") {
                  errorCallback($table, data, xhr, json);
                }

                setTimeout(function(){
                  $('#'+ $tableId +'').css('width', '100%');
                  $('#'+ $tableId +'_wrapper').find('.ajax-loader').remove();
                  $('#'+ $tableId +'').removeClass('d-none');
                }, 0);

                return true;
            } else if (xhr.status >= 500) {
                var html = '<div class="alert alert-warning d-flex align-items-center justify-content-between mb-15" role="alert">';
                html += '<div class="flex-fill mr-10">';
                html += '<p class="mb-0">we are unable to process your request at this time. please try again later.</p>';
                html += '</div>';
                html += '<div class="flex-00-auto">';
                html += '<i class="fa fa-fw fa-2x fa-exclamation-triangle"></i>';
                html += '</div>';
                html += '</div>';

                _renderAdvanceTableFilters($(this).find('thead'), $tableId);

                $(this).find('tbody').html('<tr><td align="center" colspan="'+ $(this).find('thead tr').children().length +'">'+ html +'</td></tr>');
                $(this).DataTable().clear();

                if (typeof errorCallback === "function") {
                  errorCallback($table, data, xhr, json);
                }

                setTimeout(function(){
                  $('#'+ $tableId +'').css('width', '100%');
                  $('#'+ $tableId +'_wrapper').find('.ajax-loader').remove();
                  $('#'+ $tableId +'').removeClass('d-none');
                }, 0);

                return true;
            }
            if (serverSide && !dataSrc) {
              json.recordsTotal = (json.meta) ? (json.meta.total ? json.meta.total : 0) : json.data.length;
              json.recordsFiltered = (json.meta) ? (json.meta.total ? json.meta.total : 0) : json.data.length;
              json.deferLoading = (json.meta) ? (json.meta.total ? json.meta.total : 0) : json.data.length;
            } else if (serverSide && dataSrc) {
              var dataLength = _.size(_.get(json, dataSrc));

              json.recordsTotal = (dataLength) ? dataLength : 0;
              json.recordsFiltered = (dataLength) ? dataLength : 0;
              json.deferLoading = (dataLength) ? dataLength : 0;
            }

            if (typeof successCallback === "function") {
              successCallback($table, data, xhr, json);
            }

            if (xhr.status === 0) { // Request aborted
              if (xhr.statusText === 'abort') {
                  //silent
              } else {
                setTimeout(function(){
                  $('#'+ $tableId +'').css('width', '100%');
                  $('#'+ $tableId +'_wrapper').find('.ajax-loader').remove();
                  $('#'+ $tableId +'').removeClass('d-none');
                }, 0);
              }
            } else {
              setTimeout(function(){
                $('#'+ $tableId +'').css('width', '100%');
                $('#'+ $tableId +'_wrapper').find('.ajax-loader').remove();
                $('#'+ $tableId +'').removeClass('d-none');
              }, 0);
            }
        })
        .on('error.dt', function ( e, settings, techNote, message ) {
          if (typeof errorCallback === "function") {
            errorCallback($table, data, techNote, message);
          }

          setTimeout(function(){
            $('#'+ $tableId +'').css('width', '100%');
            $('#'+ $tableId +'_wrapper').find('.ajax-loader').remove();
            $('#'+ $tableId +'').removeClass('d-none');
          }, 0);
        })
        .on('processing.dt', function ( e, settings, processing ) {
          $('#'+ $tableId +'_wrapper .ajax-loader').remove();

          $('.dataTables_processing').remove();

          if (!$table.silentLoader) {
            var html = '<div class="ajax-loader d-flex justify-content-center">';
            html += '<div class="align-self-center">';
            html += '<img src="/media/loader-anim-inline.svg" alt="loading..." class="not-ie" style="width: 100px;">';
            html += '<img src="/media/loader-anim-inline-ie.gif" alt="loading..." class="ie" style="width: 100px;">';
            html += '</div>';
            html += '</div>';

            $('#'+ $tableId +'_wrapper').prepend(html);
            $('#'+ $tableId +'_wrapper').css('min-height', '150px');

            $('#'+ $tableId +'_wrapper').removeClass('position-relative').addClass('position-relative');
            if ($('#'+ $tableId +'_wrapper').is(":visible")) {
              $('#'+ $tableId +'_wrapper').children('.ajax-loader').css('height', ''+  $('#'+ $tableId +'_wrapper').innerHeight() +'px');
              $('#'+ $tableId +'_wrapper').children('.ajax-loader').css('width', ''+  $('#'+ $tableId +'_wrapper').innerWidth() +'px');
              setTimeout(function () {
                $('#'+ $tableId +'_wrapper').children('.ajax-loader').css('height', ''+  $('#'+ $tableId +'_wrapper').innerHeight() +'px');
                $('#'+ $tableId +'_wrapper').children('.ajax-loader').css('width', ''+  $('#'+ $tableId +'_wrapper').innerWidth() +'px');
              }, 0);
            } else {
              $('#'+ $tableId +'_wrapper').children('.ajax-loader').css('height', '150px');
              $('#'+ $tableId +'_wrapper').children('.ajax-loader').css('width', '100%');
            }
          }

          if (destroy && !destroyed) {
            $('#'+ $tableId +' tbody').empty();
            destroyed = true;
          }
        })
        .DataTable({
            "lengthChange" : false,
            "processing": true,
            "oLanguage": {
                "sProcessing": ""
            },
            "searching": true,
            "serverSide": serverSide,
            "paging": paging,
            "pageLength": length,
            "bSort" : false,
            "dom": "lrtip",
            "destroy": destroy,
            "stateSave": stateSave,
            "ajax": {
                url:url,
                dataSrc: ''+ (dataSrc ? dataSrc : 'data') + '',
                statusCode: {
                  401: function() {
                    window.location.reload();
                  },
                  403: function() {
                    window.location.reload();
                  },
                  419: function() {
                    $('#modal-token-expire').modal({
                      backdrop: 'static',
                      keyboard: false
                    });
                  }
                },
                "data": function(){
                    var info = $('#'+ $tableId +'').DataTable().page.info();

                    $('#'+ $tableId +'').DataTable().ajax.url(
                      url + '?page=' +(info.page + 1)+'&'+$.param(data)+'&'+$('#'+ $tableId +'-advance-filters :input').serialize()
                    );
                },
            },
            "columns": columns,
            "initComplete": function(settings,  json) {
              $('#'+ $tableId +'').removeClass('d-none');
            },
            "drawCallback": function(settings,  json) {
              // State Save logic
              if (stateSave) {
                _toggleStateSaveTableRow($table, $tableId);
              }
            },
            "headerCallback": function( thead, data, start, end, display ) {
              _renderAdvanceTableFilters(thead, $tableId);
            },
            responsive: {
              details: {
                renderer: function ( api, rowIdx, columns ) {
                  return _renderTableAdditionalDetails(api, rowIdx, columns, $table);
                }
              }
            },
            "createdRow": createdRow
        });

        var eventValue;
        var eventTarget;
        $($table.table().container()).on( 'keyup change clear', 'input, select', _delay(function(e) {
            if (eventValue !== e.target.value || eventTarget !== e.target) {
                eventValue = e.target.value;
                eventTarget = e.target;
                if (serverSide && $(this).data('persist') !== true) {
                  $table.ajax.reload();
                  // State Save logic
                  if (stateSave) {
                    localStorage.removeItem("ExeTables_State_Data");
                  }
                }
            }

            return;
        }, 600));

        $table.on( 'responsive-display', function ( e, datatable, row, showHide, update ) {
          var displayPendingServiceMobile = ['mobile', 'mbb'];
          var displayPendingServiceBB = ['adsl', 'fibre', 'nbn'];

          if (typeof $table.actionItems != 'undefined' && $table.actionItems[row.index()] && $table.actionItems.length) {
            var node = $table.row(row.index()).node();
            var html = ($table.row(row.index()).data().type) ? '<h4>Manage service</h4>' : '';
            var activeStatus = (typeof $table.row(row.index()).data().status != "undefined") ?
              $table.row(row.index()).data().status : $table.row(row.index()).data().activeStatus;
            var serviceId = $table.row(row.index()).data().id;
            var serviceType = (typeof $table.row(row.index()).data().type != "undefined") ?
              $table.row(row.index()).data().type : $table.row(row.index()).data().productType;
            var serviceMSN = (typeof $table.row(row.index()).data().MSN != "undefined") ?
              $table.row(row.index()).data().MSN : "";
            const isProvider = serviceMSN.startsWith("06");

            if (isProvider && activeStatus === "A") {
                html = '<h4>Manage Fleet</h4>';
            }
            html += '<div class="action-item-tabs">';

            if (typeof serviceType != "undefined" && activeStatus === "P") {
                if (displayPendingServiceMobile.includes(serviceType.toLowerCase())) {
                    if (isProvider) {
                        html = '<span class="text-center"><p>This service will be activated after the credit check completed.</p></span>';
                    }else{
                        html += '<a href="/view/service/'+serviceType+'/'+serviceId+'/pending-service" >Activate service</a>\n';
                    }
                }

              if (displayPendingServiceBB.includes(serviceType.toLowerCase())) {
                html += '<a href="/view/service/'+serviceType+'/'+serviceId+'/pending-service" >Check service status</a>\n';
              }

              if (serviceType.toLowerCase() == "corporate") {
                html += '<a href="/service/'+serviceType+'/'+serviceId+
                            '/modem-order" class="btn btn-rounded btn-dual-secondary mb-5">Modem order</a>\n';
                html +='<a href="/service/'+serviceType+'/smartnet" class="btn btn-rounded btn-dual-secondary mb-5">Smartnet order</a>\n';
                html += '<a href="/service/'+serviceType+'/'+serviceId+
                            '/hosting-ip" class="btn btn-rounded btn-dual-secondary mb-5">Manage additional IP orders</a>\n';
              }
            } else  if (activeStatus === "I") {
              html += ''; // Disable other options as service is inactive
            } else {
              html += $table.actionItems[row.index()].data;
            }

            if (typeof $table.infoItems != 'undefined' && $table.infoItems[row.index()]) {
              window.exeTableData = $table.infoItems[row.index()];

              if (!isProvider) {
                html += '<a href="javascript:void(0);" onclick="openModalTableAdditionalDetailInfoItems('+ JSON.stringify($table.row(row.index()).data()).replace(/\"/g,"'") +')" >'+ (($table.row(row.index()).data().type) ? 'Service details' : 'More details') +'</a>\n';
              }
            }

            html += '</div>';

            if (typeof $table.childItems != 'undefined' && $table.childItems[row.index()]) {
              html += $table.childItems[row.index()].data;
            }

            if (showHide) {
              $(node).next('tr.child').find('.action-item-tabs').remove();
              $(node).next('tr.child').find('td.child').append(html);
              $(node).next('tr.child').find('.action-item-tabs').children().each(function() {
                $(this).removeClass();
              });
            } else {
              $(node).next('tr.child').find('.action-item-tabs').remove();
              if (typeof $table.infoItems != 'undefined' && $table.infoItems[row.index()]) {
                $table.infoItems[row.index()] = [];
              }
            }
          } else {
            var node = $table.row(row.index()).node();

            if (showHide) {
              $(node).next('tr.child').find('.additional-columns').remove();
              var html = '<table class="additional-columns" style="width:100%">';
              $table.row(row.index()).columns().responsiveHidden().each( function (val, index) {
                if (!val) {
                  var title = $table.column(index).header().textContent;
                  var data = $table.cells( null, index ).render( 'display' )[row.index()];
                  html += '<tr>';
                  html += '<td>'+title+':'+'</td>';
                  html += '<td>'+linkify(data)+'</td>';
                  html += '</tr>';
                }
              });
              html += '</table>';
              $(node).next('tr.child').find('td.child').append(html);
            } else {
              $(node).next('tr.child').find('.additional-columns').remove();
            }
          }

          // State Save logic
          if (stateSave) {
            _storeStateSaveTableRow($table, $tableId, row, showHide);
          }

          $table.rows().every( function () {
            var rowOther = this;

            if ( rowOther.child.isShown() && row.index() != rowOther.index() ) {
              rowOther.child.hide();
              $(rowOther.node()).removeClass('parent');
            }
          } );
        });

        $table.on( 'responsive-resize', function ( e, datatable, columns ) {
          if (typeof $table.infoItems != 'undefined' && $table.infoItems) {
            $table.infoItems = [];
          }

          $table.columns.adjust().responsive.recalc();
        });

        $table.on( 'page.dt', function () {
          // State Save logic
          if (stateSave) {
            localStorage.removeItem("ExeTables_State_Data");
          }
        } );
      }

      ajaxHandler();

      return $table;
    };

    function _delay(callback, ms) {
      var timer = 0;
      return function() {
          var context = this, args = arguments;
          clearTimeout(timer);
          timer = setTimeout(function () {
          callback.apply(context, args);
          }, ms || 0);
      };
    }

    function _renderAdvanceTableFilters(thead, $tableId)
    {
      var html = '<div id="'+ $tableId +'-advance-filters-toggle" class="table-advance-filters-toggle">';
      html += '<a onclick="_toggleAdvanceTableFilters(this,`'+ $tableId +'`)"><i class="si si-equalizer"></i> Filters <img src="/media/icon-arrow-down.svg" alt="Expand" class="mt-2"></a>';
      html += '</div>';
      html += '<div id="'+ $tableId +'-advance-filters" class="col-12 table-advance-filters">';
      html += '<div id="'+ $tableId +'-advance-filters-wrapper" style="display:none;">';
      html += '<h4>What are you looking for?</h4>';
      html += '<div class="row">';
      $(thead).find(':input').each(function( index, element ) {
        html += '<div class="col-md-3 p-0">';
        html += '<div class="form-group">';
        html += '<label for="'+ ($(element).closest('th').clone().children().remove().end().text().trim().replace(/\s+/g, '-').toLowerCase()) +'" class="col-12">'+ ($(element).closest('th').clone().children().remove().end().text()) +'</label>';
        html += '<div class="col-12">';
        html += ''+ $(element).prop('outerHTML') + '';
        html += '</div>';
        html += '</div>';
        html += '</div>';
      });
      html += '</div>';
      html += '</div>';
      html += '</div>';

      if ($(thead).find(':input').length) {
        $('#'+ $tableId +'').find('#'+ $tableId +'-advance-filters').remove();
        $('#'+ $tableId +'-advance-filters-toggle').remove();
        $(thead).parent().parent().parent().prepend(html);
        $(thead).find(':input').remove();
      }
    }

    function _renderTableAdditionalDetails(api, rowIdx, columns, $table)
    {
      var data = $.map( columns, function ( col, i ) {
      return col.hidden && (col.title != 'Action' && col.title != 'Child') ?
        _setTableAdditionalDetailInfoItems(api, rowIdx, col, $table) :
          ((col.hidden && col.title === 'Action' && col.title != 'Child')) ?
            _setTableAdditionalDetailActionItems(api, rowIdx, col, $table) :
            ((col.hidden && col.title === 'Child' && col.title != 'Action') ?
            _setTableChildItems(api, rowIdx, col, $table) :
          '');
      }).join('');

      return '';
    }

    function _setTableAdditionalDetailActionItems(api, rowIdx, col, $table)
    {
      if (typeof $table.actionItems === 'undefined') {
        $table.actionItems = [];
        $table.actionItems[rowIdx] = col;
      } else {
        $table.actionItems[rowIdx] = col;
      }
    }

    function _setTableAdditionalDetailInfoItems(api, rowIdx, col, $table)
    {
      if (typeof $table.infoItems === 'undefined') {
        $table.infoItems = [];
        $table.infoItems[rowIdx] = [];
        $table.infoItems[rowIdx].push({title: col.title, data: (col.data != '' ? col.data : '-')});
      } else {
        if (typeof $table.infoItems[rowIdx] === 'undefined') {
          $table.infoItems[rowIdx] = [];
          $table.infoItems[rowIdx].push({title: col.title, data: (col.data != '' ? col.data : '-')});
        } else {
          var entry = {title: col.title, data: (col.data != '' ? col.data : '-')};
          if (!_.filter($table.infoItems[rowIdx], _.matches(entry)).length) {
            $table.infoItems[rowIdx].push(entry);
          }
        }
      }
    }

    function _setTableChildItems(api, rowIdx, col, $table)
    {
        if (typeof $table.childItems === 'undefined') {
          $table.childItems = [];
          $table.childItems[rowIdx] = col;
        } else {
          $table.childItems[rowIdx] = col;
        }
    }

    window._toggleAdvanceTableFilters = function(element, $tableId)
    {
      $('#'+ $tableId +'-advance-filters-wrapper').slideToggle(400,'swing', function() {
        if ($(element).find('img').hasClass('transform-180')) {
          $(element).find('img').removeClass('transform-180').addClass('transform-0');
        } else {
            $(element).find('img').removeClass('transform-0').addClass('transform-180');
        }
      });
    }

    function _toggleStateSaveTableRow($table, $tableId)
    {
      var tabId = null;
      var activePage = location.pathname;
      var previousData = JSON.parse(localStorage.getItem("ExeTables_State_Data"));

      if ($('#'+ $tableId +'_wrapper').parents('.tab-pane').length == 1) {
        tabId = $('#'+ $tableId +'_wrapper').parents('.tab-pane').attr('id');

        // Default active tab
        if ($('#'+ $tableId +'_wrapper').parents('.tab-pane').hasClass('active')) {
          if (previousData) {

            if (previousData.page === activePage) {
              if (previousData.table.id === $tableId) {
                var node = $table.row(previousData.table.rowIndex).node();
                $(node).find('td:first-child').trigger('click');

                setTimeout(function() {
                  $('html, body').animate({
                    scrollTop: $(node).offset().top
                  }, 600);
                }, 100);
              } else {
                // Silent
              }
            } else {
              localStorage.removeItem("ExeTables_State_Data");
            }
          }
        } else { // Not active tab
          if (previousData) {

            if (previousData.page === activePage) {
              if (previousData.table.id === $tableId) {
                if (previousData.table.tab) {
                  $($("a[href$='#"+ previousData.table.tab +"']")).tab('show');
                  var node = $table.row(previousData.table.rowIndex).node();
                  $(node).find('td:first-child').trigger('click');

                  setTimeout(function() {
                    $('html, body').animate({
                      scrollTop: $(node).offset().top
                    }, 600);
                  }, 100);
                }
              } else {
                // Silent
              }
            } else {
              localStorage.removeItem("ExeTables_State_Data");
            }
          }
        }
      } else { // General
        if (previousData) {

          if (previousData.page === activePage) {
            if (previousData.table.id === $tableId) {
              var node = $table.row(previousData.table.rowIndex).node();
              $(node).find('td:first-child').trigger('click');

              setTimeout(function() {
                $('html, body').animate({
                  scrollTop: $(node).offset().top
                }, 600);
              }, 100);
            } else {
              // Silent
            }
          } else {
            localStorage.removeItem("ExeTables_State_Data");
          }
        }
      }
    }

    function _storeStateSaveTableRow($table, $tableId, row, showHide)
    {
      var tabId = null;
      var activeTab = null;
      var activePage = location.pathname;
      var previousData = JSON.parse(localStorage.getItem("ExeTables_State_Data"));

      if (showHide) {
        if ($('#'+ $tableId +'_wrapper').parents('.tab-pane').length == 1) {
          tabId = $('#'+ $tableId +'_wrapper').parents('.tab-pane').attr('id');

          // Default active tab
          if ($('#'+ $tableId +'_wrapper').parents('.tab-pane').hasClass('active')) {
            activeTab = tabId;

            var state = {
              time:    +new Date(),
              page: activePage,
              table: {
                id: $tableId,
                tab: activeTab,
                rowIndex: row.index(),
              }
            };

            localStorage.setItem("ExeTables_State_Data", JSON.stringify(state));
            localStorage.setItem("ExeTables_State_TableData", JSON.stringify(row.data()));
          } else { // Not active tab
            // Strange, should not reach here
          }
        } else { // General
          var state = {
            time:    +new Date(),
            page: activePage,
            table: {
              id: $tableId,
              tab: activeTab,
              rowIndex: row.index(),
            }
          };

          localStorage.setItem("ExeTables_State_Data", JSON.stringify(state));
          localStorage.setItem("ExeTables_State_TableData", JSON.stringify(row.data()));
        }
      } else {
        if (previousData) {
          if (previousData.page === activePage) {
            if (previousData.table.id === $tableId && previousData.table.rowIndex === row.index()) {
              localStorage.removeItem("ExeTables_State_Data");
              localStorage.removeItem("ExeTables_State_TableData");
            }
          }
        }
      }
    }

    return $.fn.exeTable = function(options) {
      if (options === null || typeof options === 'undefined') {
        options = {};
      }

      return $(this).exeTableInit(options);
    };
})(jQuery);
